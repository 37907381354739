import { render, staticRenderFns } from "./PsinList.vue?vue&type=template&id=d27a79c8&scoped=true&"
import script from "./PsinList.vue?vue&type=script&lang=js&"
export * from "./PsinList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d27a79c8",
  null
  
)

export default component.exports